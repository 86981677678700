import { mean } from 'd3';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Vector3 } from 'three';

import { isDefined } from '../../../../js/utils/variables';
import { DataTable, DataTableColumns, DataTableSortType } from '../../data-table/data-table';
import { IconPanel } from '../../icon-panel/icon-panel';
import { OverlayLoader } from '../../overlay-loader/overlay-loader';
import { Stack } from '../../stack/stack';
import { TextBox } from '../../text-box/text-box';
import { GeodataContext } from '../geodata-state';
import { PlaceMarkersContext } from './place-markers-state';

const pageSize = 5;
type Item = {
  id: string;
  name: string;
  enu: number[];
  errorX: number;
  errorY: number;
  errorZ: number;
  errorTotal: number;
};

const searchKeys = ['name'] as const;

function filterItems(items: Item[], searchText: string): Item[] {
  const text = searchText.trim().toLowerCase();
  if (text.length === 0) {
    return items;
  }

  return items.filter((item) =>
    searchKeys.some((x) => item[x]?.toLocaleLowerCase().includes(text)),
  );
}

export const NoGcpPanel = () => {
  const { t } = useTranslation();

  return (
    <NoGcpPanelStyle>
      <IconPanel
        header={{ icon: { icon: ['fad', 'magnifying-glass'], opacity: '50%', size: '4x' } }}
        responsiveness={{ compressed: false }}
      >
        {{
          title: t('gcpList.noGcp', { ns: 'cloudProcessing' }),
          info: t('gcpList.noGcpInfo', { ns: 'cloudProcessing' }),
        }}
      </IconPanel>
    </NoGcpPanelStyle>
  );
};

const NoGcpPanelStyle = styled.div`
  display: flex;
  padding: 1em;
`;

const columns: DataTableColumns<Item> = {
  name: {
    sortableBy: { type: DataTableSortType.STRING },
    unfilterable: true,
    title: 'Namn',
  },
  errorX: {
    sortableBy: { type: DataTableSortType.NUMBER },
    unfilterable: true,
    title: 'X',
    formatter: (value) => value.toFixed(3),
  },
  errorY: {
    sortableBy: { type: DataTableSortType.NUMBER },
    unfilterable: true,
    title: 'Y',
    formatter: (value) => value.toFixed(3),
  },
  errorZ: {
    sortableBy: { type: DataTableSortType.NUMBER },
    unfilterable: true,
    title: 'Z',
    formatter: (value) => value.toFixed(3),
  },
  errorTotal: {
    sortableBy: { type: DataTableSortType.NUMBER },
    unfilterable: true,
    title: 'Total',
    formatter: (value) => value.toFixed(3),
  },
};

export const GcpList = () => {
  const { isGeodataProcessing, geodata } = useContext(GeodataContext);
  const { gcp, selectGcp, markers } = useContext(PlaceMarkersContext);
  const { t } = useTranslation();

  const [items, setItems] = useState<Item[]>([]);
  const [searchText, setSearchText] = useState('');
  const [filteredItems, setFilteredItems] = useState<Item[]>([]);

  const renderNoImagesPanel = items.length === 0;

  useEffect(() => {
    if (isDefined(geodata.gcpCollection?.points)) {
      const newItems = geodata.gcpCollection.points.map((x) => {
        const totalErrors = markers
          .filter(
            (marker) => marker.pinned && marker.gcpId === x.id && isDefined(marker.residualError),
          )
          .map((marker) => new Vector3(...marker.residualError!));
        return {
          id: x.id!,
          name: x.name,
          enu: x.enu,
          errorX: mean(totalErrors.map((error) => error.x)) ?? 0,
          errorY: mean(totalErrors.map((error) => error.y)) ?? 0,
          errorZ: mean(totalErrors.map((error) => error.z)) ?? 0,
          errorTotal: mean(totalErrors.map((error) => error.length())) ?? 0,
        };
      });

      setItems(newItems);
    } else {
      setItems([]);
    }
  }, [geodata, markers]);

  useEffect(() => {
    setFilteredItems(filterItems(items, searchText));
  }, [items, searchText]);

  const highlightRow = useCallback((item: Item) => item.id === gcp?.id, [gcp]);

  return (
    <OverlayLoader visible={isGeodataProcessing}>
      <Stack spacing={1}>
        <Stack alignItems="center" direction="row" spacing={0.5}>
          <TextBox
            placeholder={t('search', { ns: 'common' })}
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
        </Stack>

        {renderNoImagesPanel ? (
          <NoGcpPanel />
        ) : (
          <DataTable
            columns={columns}
            fixedLayout={true}
            highlightRow={highlightRow}
            items={filteredItems}
            pageSize={pageSize}
            onRowClicked={selectGcp}
          />
        )}
      </Stack>
    </OverlayLoader>
  );
};
