import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { t, TFunction } from 'i18next';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { DefaultTheme, useTheme } from 'styled-components';
import { Box3, OrthographicCamera, Vector3 } from 'three';

import { GeodataStatus } from '../../../../../typings/api/skymap/rest/v1/.common';
import { GeodataStore } from '../../../../js/stores/geodata-store';
import { ProjectStore } from '../../../../js/stores/project-store';
import { isDefined } from '../../../../js/utils/variables';
import { CoordinateManager } from '../../../../js/viewer/coordinate-manager';
import { Coordinates } from '../../../../js/viewer/utilities/math-helpers';
import { useObject3d } from '../../../hooks/three/use-object-3d';
import { useOrbitControls } from '../../../hooks/three/use-orbit-controls';
import { useWms } from '../../../hooks/three/use-wms';
import { useDialog } from '../../../hooks/use-dialog';
import { useRouter } from '../../../hooks/use-router';
import { reset } from '../../../utils/styled-reset';
import { Button } from '../../button/button';
import { Dialog } from '../../dialog/dialog';
import { FileSelectionButton } from '../../file-selection-button/file-selection-button';
import { Icon } from '../../icon/icon';
import { IconPanel } from '../../icon-panel/icon-panel';
import { InfoBox } from '../../info-box/info-box';
import { ToolIcon } from '../../skyview-new/toolbar/tool-icon';
import { ToolbarGroup } from '../../skyview-new/toolbar/toolbar-group';
import { Stack } from '../../stack/stack';
import { GeodataConfiguration } from '../geodata-configuration/geodata-configuration';
import { GeodataImagesContext, GeodataImagesState } from '../geodata-images-state';
import { GeodataContext, GeodataState } from '../geodata-state';
import { geodataStatuses } from '../use-geodata';
import { sfmViewerLayering } from './layering';
import { PlaceMarkersContext, PlaceMarkersState } from './place-markers-state';
import { PlaceMarkerViewer } from './place-markers-viewer';
import { SfmViewerRightMenu } from './sfm-viewer-right-menu';
import { SfmViewerTooltip } from './sfm-viewer-tooltip';
import { useCursor } from './use-cursor';
import { useGcpPoints } from './use-gcp-points';
import { getPositionFromGeodataImageTransform, useGeodataCameras } from './use-geodata-cameras';
import { useSfmRenderingContext } from './use-sfm-rendering-context';
import { useSparseReconstruction } from './use-sparse-reconstruction';

function useProjectProjection() {
  const { images } = useContext(GeodataImagesContext);
  const [origo, setOrigo] = useState<Vector3 | undefined>(
    CoordinateManager.getInstance().getProjectionOrigo(),
  );
  const [projection, setProjection] = useState<string | undefined>(
    ProjectStore.instance.coordinateSystem?.proj4,
  );

  useEffect(() => {
    const proj4Definition = ProjectStore.instance.coordinateSystem?.proj4;

    if (!isDefined(proj4Definition) || !images.some((x) => isDefined(x.transform))) {
      return undefined;
    }

    const coords: Coordinates[] = [];
    for (const item of images) {
      if (isDefined(item.transform)) {
        const cameraEnuPosition = getPositionFromGeodataImageTransform(
          item.transform,
          proj4Definition,
        );
        coords.push(cameraEnuPosition);
      }
    }

    const currentOrigo = CoordinateManager.getInstance().getProjectionOrigo() ?? new Vector3();
    const points = coords.map((x) => new Vector3(x.easting, x.northing, x.height));
    const boundingBox = new Box3().setFromPoints(points);
    const origoMoved = boundingBox.min.distanceTo(currentOrigo) > 1000;

    if (origoMoved) {
      CoordinateManager.getInstance().setProjectProjectionData(proj4Definition, boundingBox.min);

      setOrigo(boundingBox.min);
      setProjection(proj4Definition);
    }
  }, [images]);

  return { origo, projection };
}

/**
 * SfM viewer (Structure from motion).
 *
 * Works as an interface towards the SfM step in photogrammetry which is an intermediate state
 * where the camera poses and sparse reconstruction can be refined before moving on to dense
 * reconstruction.
 */
export const SfmViewer = () => {
  return (
    <GeodataState geodataId={GeodataStore.instance.geodata.id}>
      <GeodataImagesState>
        <PlaceMarkersState>
          <SfmViewerContent />
        </PlaceMarkersState>
      </GeodataImagesState>
    </GeodataState>
  );
};

const SfmViewerContent = () => {
  const webGlContainer = React.useRef<HTMLDivElement>(null);
  const css3dContainer = React.useRef<HTMLDivElement>(null);
  const wmsRenderTarget = useRef<HTMLDivElement>(null);
  const { geodata, isPending, isGeodataProcessing } = useContext(GeodataContext);
  const { images, isPendingImages } = useContext(GeodataImagesContext);
  const { showPlaceMarkersDialog } = useContext(PlaceMarkersContext);

  const introductionDialog = useDialog();
  const settingsDialog = useDialog();
  const placeMarkersDialog = useDialog();

  const [isLegacyProcess, setIsLegacyProcess] = useState(false);

  useEffect(() => {
    const isLoading = isPending || isPendingImages;
    const hasImages = images.length > 0;
    const isLegacy = geodata.photoCount > 0;

    if (isLegacy) {
      setIsLegacyProcess(isLegacy);
      introductionDialog.show();
      return;
    }

    if (isLoading) {
      return;
    }

    if (hasImages) {
      introductionDialog.hide();
    } else {
      introductionDialog.show();
    }
  }, [introductionDialog, geodata, isPendingImages, images, isPending, isGeodataProcessing]);

  const { webGlScene, css2dScene, wmsScene, camera, setCameraType } = useSfmRenderingContext(
    webGlContainer,
    css3dContainer,
  );

  const { origo, projection } = useProjectProjection();
  const { cameras } = useGeodataCameras(origo);
  const { sparsePointCloud } = useSparseReconstruction(origo);
  const { gcp } = useGcpPoints(origo);
  const [showSparsePoints, setShowSparsePoints] = useState(true);
  const [showWms, setShowWms] = useState(false);
  const controls = useOrbitControls(
    css3dContainer,
    camera,
    cameras?.points.geometry.boundingSphere?.center,
  );

  const { wmsQuad } = useWms(wmsRenderTarget, controls, showWms, projection);

  const isOrthoView = useMemo(() => camera instanceof OrthographicCamera, [camera]);
  const quad = useMemo(() => (isOrthoView ? wmsQuad : undefined), [isOrthoView, wmsQuad]);

  useObject3d(wmsScene, quad);
  useObject3d(webGlScene, sparsePointCloud);
  useObject3d(webGlScene, gcp?.points);
  useObject3d(css2dScene, gcp?.labels);
  useObject3d(webGlScene, cameras?.points);

  const { intersection } = useCursor(
    css3dContainer,
    css2dScene,
    webGlScene,
    controls,
    camera,
    cameras?.points,
    gcp?.points,
    sparsePointCloud,
  );

  useEffect(() => {
    if (showPlaceMarkersDialog) {
      placeMarkersDialog.show();
    } else {
      placeMarkersDialog.hide();
    }
  }, [showPlaceMarkersDialog, placeMarkersDialog]);

  const isCenterEnabled = useMemo(
    () => isDefined(camera) && isDefined(controls) && isDefined(cameras?.points),
    [camera, controls, cameras],
  );

  const centerCamera = useCallback(() => {
    if (isDefined(camera) && isDefined(controls) && isDefined(cameras?.points)) {
      controls.target.copy(cameras.points.geometry.boundingSphere!.center);
      camera.position.copy(controls.target.clone().add(new Vector3(0, 0, 100)));
      controls.update();
    }
  }, [controls, cameras, camera]);

  const [hasFocused, setHasFocused] = useState(true);

  useEffect(() => setHasFocused(!isDefined(projection)), [projection]);

  useEffect(() => {
    if (hasFocused || !isCenterEnabled) {
      return;
    }

    setHasFocused(true);
    centerCamera();
  }, [centerCamera, hasFocused, isCenterEnabled]);

  return (
    <Component>
      <StyledStack direction="row" spacing={0}>
        <Viewer>
          <WebGlCanvasContainer ref={webGlContainer} />
          <Css3dCanvasContainer ref={css3dContainer} />
          <WmsRenderTarget>
            <OlCanvas ref={wmsRenderTarget} />
          </WmsRenderTarget>

          <SfmViewerTooltip
            camerasObjectUuid={cameras?.points.uuid}
            gcpObjectUuid={gcp?.points.uuid}
            intersection={intersection}
            pointerRef={css3dContainer}
            webGlScene={webGlScene}
          />

          <Toolbar direction="row" spacing={0.2}>
            <Positioned>
              <ToolbarGroup>
                <ToolIcon
                  active={isOrthoView}
                  callback={() => setCameraType('orthographic')}
                  icon={['fal', 'image']}
                  tooltip={{
                    title: t('orthophoto.tooltip.title', { ns: 'skyviewToolbar' }),
                    text: t('orthophoto.tooltip.text', { ns: 'skyviewToolbar' }),
                  }}
                />
                <ToolIcon
                  active={!isOrthoView}
                  callback={() => setCameraType('perspective')}
                  icon={['fal', 'cube']}
                  tooltip={{
                    title: t('perspective.tooltip.title', { ns: 'skyviewToolbar' }),
                    text: t('perspective.tooltip.text', { ns: 'skyviewToolbar' }),
                  }}
                />
              </ToolbarGroup>

              <ToolbarGroup>
                <ToolIcon
                  active={showSparsePoints && isDefined(sparsePointCloud)}
                  callback={() =>
                    setShowSparsePoints((oldState) => {
                      sparsePointCloud!.visible = !oldState;
                      return !oldState;
                    })
                  }
                  disabled={!isDefined(sparsePointCloud)}
                  icon={['fal', 'cloud']}
                  tooltip={{
                    title: t('aligner.preview', { ns: 'cloudProcessing' }),
                    text: t('aligner.previewInfo', { ns: 'cloudProcessing' }),
                  }}
                />

                <ToolIcon
                  active={false}
                  callback={() => centerCamera()}
                  disabled={!isCenterEnabled}
                  icon={['fal', 'expand']}
                  tooltip={{
                    title: t('focus.tooltip.title', { ns: 'skyviewToolbar' }),
                    text: t('focus.tooltip.text', { ns: 'skyviewToolbar' }),
                  }}
                />

                <ToolIcon
                  active={showWms}
                  callback={() => {
                    setShowWms((oldState) => {
                      return !oldState;
                    });
                  }}
                  disabled={!isOrthoView}
                  icon={['fal', 'map']}
                  tooltip={{
                    title: t('wms.tooltip.title', { ns: 'skyviewToolbar' }),
                    text: t('wms.tooltip.text', { ns: 'skyviewToolbar' }),
                  }}
                />

                <ToolIcon
                  active={false}
                  callback={() => settingsDialog.show()}
                  icon={['fad', 'cog']}
                  tooltip={{
                    title: t('settings.title', { ns: 'cloudProcessing' }),
                    text: t('settings.title', { ns: 'cloudProcessing' }),
                  }}
                />
              </ToolbarGroup>
            </Positioned>
          </Toolbar>

          {placeMarkersDialog.visible && (
            <PlaceMarkerContainer>
              <PlaceMarkerViewer />
            </PlaceMarkerContainer>
          )}
        </Viewer>

        <SfmViewerRightMenu />

        <StatusIcon showWms={showWms} />
      </StyledStack>
      {introductionDialog.render(
        <IntroductionDialog dialog={introductionDialog} isLegacyProcess={isLegacyProcess} />,
      )}
      {settingsDialog.render(<SettingsDialog dialog={settingsDialog} />)}
    </Component>
  );
};

export const IntroductionPanel = () => {
  const { t } = useTranslation();

  return (
    <IntroductionPanelStyle>
      <IconPanel
        header={{ icon: { icon: ['fad', 'drone'], opacity: '50%', size: '4x' } }}
        responsiveness={{ compressed: false }}
      >
        {{
          title: t('aligner.addImages', { ns: 'cloudProcessing' }),
          info: t('aligner.introductionInfo', { ns: 'cloudProcessing' }),
        }}
      </IconPanel>
    </IntroductionPanelStyle>
  );
};

const IntroductionPanelStyle = styled.div`
  display: flex;
  padding: 1em;
`;

const IntroductionDialog = ({
  dialog,
  isLegacyProcess,
}: {
  isLegacyProcess: boolean;
  dialog: ReturnType<typeof useDialog>;
}) => {
  const context = useContext(GeodataContext);
  const { uploadImage } = useContext(GeodataImagesContext);
  const { redirectToAngularState } = useRouter();

  return (
    <Dialog
      closeIcon={false}
      context={(children) => (
        <GeodataContext.Provider value={context}>{children}</GeodataContext.Provider>
      )}
      maxHeight="expand"
      maxWidth={'90%'}
      onClose={dialog.hide}
    >
      {{
        header: t('aligner.title', { ns: 'cloudProcessing' }),
        content: (
          <Stack spacing={1}>
            {!isLegacyProcess && <IntroductionPanel />}
            {isLegacyProcess && (
              <InfoBox color="yellow">
                {t('aligner.incompatibleCloudProcess', { ns: 'cloudProcessing' })}
              </InfoBox>
            )}
          </Stack>
        ),
        footer: {
          right: (
            <>
              {!isLegacyProcess && (
                <FileSelectionButton
                  accept=".jpg,.jpeg"
                  color={'primary'}
                  leftIcon={{ icon: ['fad', 'images'] }}
                  multiple={true}
                  text="Lägg till bilder"
                  variant="contained"
                  onFilesSelected={async (files) => {
                    for (const file of files) {
                      await uploadImage(file);
                    }
                    dialog.hide();
                  }}
                />
              )}

              <Button
                variant="text"
                onClick={() =>
                  redirectToAngularState('sky.project.details.geodata', {
                    projectId: ProjectStore.instance.project!.id,
                  })
                }
              >
                {t('close', { ns: 'common' })}
              </Button>
            </>
          ),
        },
      }}
    </Dialog>
  );
};

function getGeodataStatusIconText(status: GeodataStatus, t: TFunction): string {
  switch (status) {
    case 'align_cameras':
      return t('statuses.alignCameras', { ns: 'cloudProcessing' });
    case 'align_photos_failed':
      return t('statuses.alignPhotosFailed', { ns: 'cloudProcessing' });
    case 'align_photos_pending':
      return t('statuses.alignPhotosPending', { ns: 'cloudProcessing' });
    case 'align_photos_preparing':
      return t('statuses.alignPhotosPreparing', { ns: 'cloudProcessing' });
    case 'build_depth_maps':
      return t('statuses.buildDepthMaps', { ns: 'cloudProcessing' });
    case 'build_model':
      return t('statuses.buildModel', { ns: 'cloudProcessing' });
    case 'build_orthomosaic':
      return t('statuses.buildOrthomosaic', { ns: 'cloudProcessing' });
    case 'build_tiled_model':
      return t('statuses.buildTiledModel', { ns: 'cloudProcessing' });
    case 'created':
      return t('statuses.created', { ns: 'cloudProcessing' });
    case 'export_points':
      return t('statuses.exportPoints', { ns: 'cloudProcessing' });
    case 'export_raster':
      return t('statuses.exportRaster', { ns: 'cloudProcessing' });
    case 'generate_pointcloud_failed':
      return t('statuses.generatePointcloudFailed', { ns: 'cloudProcessing' });
    case 'generate_pointcloud_finished':
      return t('statuses.generatePointcloudFinished', { ns: 'cloudProcessing' });
    case 'generate_skyview_data_failed':
      return t('statuses.generateSkyviewDataFailed', { ns: 'cloudProcessing' });
    case 'generate_skyview_data_finished':
      return t('statuses.generateSkyviewDataFinished', { ns: 'cloudProcessing' });
    case 'generate_skyview_data_pending':
      return t('statuses.generateSkyviewDataPending', { ns: 'cloudProcessing' });
    case 'generate_tif_finished':
      return t('statuses.generateTifFinished', { ns: 'cloudProcessing' });
    case 'generate_tif_pending':
      return t('statuses.generateTifPending', { ns: 'cloudProcessing' });
    case 'generate_tif_preparing':
      return t('statuses.generateTifPreparing', { ns: 'cloudProcessing' });
    case 'match_photos':
      return t('statuses.matchPhotos', { ns: 'cloudProcessing' });
    case 'optimize_cameras':
      return t('statuses.optimizeCameras', { ns: 'cloudProcessing' });
    case 'optimize_pending':
      return t('statuses.optimizePending', { ns: 'cloudProcessing' });
    case 'optimize_running':
      return t('statuses.optimizeRunning', { ns: 'cloudProcessing' });
    case 'user_move_flags':
      return t('statuses.userMoveFlags', { ns: 'cloudProcessing' });
    case 'generating_skyview_data' as GeodataStatus:
      return t('statuses.generatingSkyviewData', { ns: 'cloudProcessing' });
    case 'optimize_pending_reset_alignment' as GeodataStatus:
      return t('statuses.optimizePendingResetAlignment', { ns: 'cloudProcessing' });
    default:
      return '...';
  }
}

function getGeodataStatusIconContent(
  status: GeodataStatus,
  theme: DefaultTheme,
  t: TFunction,
): {
  icon: IconProp;
  text: string;
  spin: boolean;
  color: string;
} {
  const text = getGeodataStatusIconText(status, t);

  if (geodataStatuses.failedStatuses.includes(status)) {
    return {
      icon: ['fad', 'warning'],
      color: theme.color.red,
      spin: false,
      text,
    };
  }

  if (geodataStatuses.successStatuses.includes(status)) {
    return {
      icon: ['fad', 'check-circle'],
      color: theme.color.green,
      spin: false,
      text,
    };
  }

  return {
    icon: ['fad', 'spinner'],
    color: theme.color.blue,
    spin: true,
    text,
  };
}

const StatusIcon = ({ showWms }: { showWms: boolean }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { geodata } = useContext(GeodataContext);
  const iconConfig = useMemo(
    () => getGeodataStatusIconContent(geodata.status as GeodataStatus, theme, t),
    [geodata, theme, t],
  );

  if (geodataStatuses.placeMarkerStatuses.includes(geodata.status)) {
    return null;
  }

  return (
    <StatusIconStyle>
      <Stack direction="row" spacing={0.5}>
        <Icon
          color={iconConfig.color}
          fixedWidth={true}
          icon={iconConfig.icon}
          size="lg"
          spin={iconConfig.spin}
          title={iconConfig.text}
        />
        <StatusIconLabel showWms={showWms}>{iconConfig.text}</StatusIconLabel>
      </Stack>
    </StatusIconStyle>
  );
};

const StatusIconStyle = styled.div`
  position: absolute;
  left: 1em;
  bottom: 1em;
`;

const StatusIconLabel = styled.label<{ showWms: boolean }>`
  color: ${({ showWms }) => (showWms ? 'black' : 'white')};
  font-weight: 500;
`;

const SettingsDialog = (props: { dialog: ReturnType<typeof useDialog> }) => {
  const confirmButtonRef = useRef<HTMLButtonElement>(null);
  const [isPending, setIsPending] = useState(false);
  const context = useContext(GeodataContext);

  return (
    <Dialog
      closeIcon={true}
      context={(children) => (
        <GeodataContext.Provider value={context}>{children}</GeodataContext.Provider>
      )}
      maxHeight="expand"
      maxWidth={'90%'}
      onClose={props.dialog.hide}
    >
      {{
        header: t('settings.title', { ns: 'cloudProcessing' }),
        content: (
          <GeodataConfiguration
            saveSettingsButtonRef={confirmButtonRef}
            onDialogIsPendingChanged={(state) => setIsPending(state)}
            onSuccess={props.dialog.hide}
          />
        ),
        footer: {
          right: (
            <>
              <Button
                color="primary"
                disabled={isPending}
                ref={confirmButtonRef}
                variant="contained"
              >
                {t('confirm', { ns: 'common' })}
              </Button>
              <Button variant="text" onClick={props.dialog.hide}>
                {t('close', { ns: 'common' })}
              </Button>
            </>
          ),
        },
      }}
    </Dialog>
  );
};

const StyledStack = styled(Stack)`
  position: relative;
  height: 100%;
`;

const Toolbar = styled(Stack)`
  align-items: center;
  justify-content: center;
`;

const Positioned = styled.div`
  position: absolute;
  bottom: 16px;

  display: flex;
  flex-wrap: wrap;
  border-radius: 5px;

  margin: 0 16px;
  padding: 8px;

  background-color: #fff;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);

  z-index: ${sfmViewerLayering.menu};
`;

const Component = styled.div`
  ${reset}
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Viewer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const WebGlCanvasContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: ${sfmViewerLayering.webGlCanvasContainer};
`;

const PlaceMarkerContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: ${sfmViewerLayering.placeMarkersContainer};
`;

const Css3dCanvasContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: ${sfmViewerLayering.css3dCanvasContainer};
`;

const WmsRenderTarget = styled.div`
  position: absolute;

  height: 2048px;
  width: 2048px;

  visibility: hidden;
`;

const OlCanvas = styled.div`
  position: absolute;

  height: 100%;
  width: 100%;
`;

SfmViewer.propTypes = {
  wrapWithLanguageProvider: PropTypes.any,
};
