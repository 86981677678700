import { t } from 'i18next';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { isDefined } from '../../../../js/utils/variables';
import { DataTable, DataTableColumns, DataTableSortType } from '../../data-table/data-table';
import { FileSelectionIcon } from '../../file-selection-icon/file-selection-icon';
import { Icon } from '../../icon/icon';
import { IconPanel } from '../../icon-panel/icon-panel';
import { OverlayLoader } from '../../overlay-loader/overlay-loader';
import { Stack } from '../../stack/stack';
import { TextBox } from '../../text-box/text-box';
import { GeodataImageItemWithMarkers, PlaceMarkersContext } from '../gcp-page/place-markers-state';
import { GeodataImagesContext } from '../geodata-images-state';
import { GeodataContext } from '../geodata-state';

const pageSize = 20;

const searchKeys = ['displayName', 'status'] as const;

function filterItems(
  items: GeodataImageItemWithMarkers[],
  searchText: string,
): GeodataImageItemWithMarkers[] {
  const text = searchText.trim().toLowerCase();
  if (text.length === 0) {
    return items;
  }

  return items.filter((item) =>
    searchKeys.some((x) => item[x]?.toLocaleLowerCase().includes(text)),
  );
}

export const NoImagesPanel = () => {
  return (
    <NoImagesPanelStyle>
      <IconPanel
        header={{ icon: { icon: ['fad', 'magnifying-glass'], opacity: '50%', size: '4x' } }}
        responsiveness={{ compressed: false }}
      >
        {{
          title: t('imageList.noImagesTitle', { ns: 'cloudProcessing' }),
          info: t('imageList.noImagesInfo', { ns: 'cloudProcessing' }),
        }}
      </IconPanel>
    </NoImagesPanelStyle>
  );
};

const NoImagesPanelStyle = styled.div`
  display: flex;
  padding: 1em;
`;

const columns: DataTableColumns<GeodataImageItemWithMarkers> = {
  statusIcon: {
    title: 'Status',
    alignment: 'center',
    width: '45px',
    sortableBy: { type: DataTableSortType.STRING, columnKey: 'status' },
    unfilterable: true,
  },
  markerStatusIcon: {
    title: 'Marker status',
    alignment: 'center',
    width: '45px',
    sortableBy: { type: DataTableSortType.STRING, columnKey: 'markerStatus' },
    unfilterable: true,
  },
  displayName: {
    sortableBy: { type: DataTableSortType.STRING },
    unfilterable: true,
    title: 'Namn',
  },
  totalStandardDeviation: {
    sortableBy: { type: DataTableSortType.NUMBER },
    unfilterable: true,
    title: 'Standardavvikelse',
    width: '75px',
    formatter(value) {
      return value.toFixed(3);
    },
  },
};

const tableStyle = {
  rowCell: {
    fontSize: '14px',
  },
  headerCell: {
    fontSize: '14px',
  },
};

/**
 * Lists the images related to the Geodata with the option to upload or remove existing images.
 * Adding or removing images will be disabled when the process has failed or finished.
 */
export function GeodataImageList() {
  const theme = useTheme();
  const { isGeodataProcessing } = useContext(GeodataContext);
  const { isPendingImages, uploadImage, linkLocalImages, refetchImages } =
    useContext(GeodataImagesContext);
  const { setShowPlaceMarkersDialog, image, selectImage, imagesWithMarkers, setSortedImageList } =
    useContext(PlaceMarkersContext);
  const [filteredItems, setFilteredItems] = useState<GeodataImageItemWithMarkers[]>([]);
  const [sortedItems, setSortedItems] = useState<GeodataImageItemWithMarkers[]>([]);
  const [searchText, setSearchText] = useState('');
  const renderNoImagesPanel = !isPendingImages && imagesWithMarkers.length === 0;

  useEffect(() => {
    setFilteredItems(filterItems(imagesWithMarkers, searchText));
  }, [imagesWithMarkers, searchText]);

  const highlightRow = useCallback(
    (item: GeodataImageItemWithMarkers) => item.imageId === image?.imageId,
    [image],
  );

  const onRowClicked = useCallback(
    async (item: GeodataImageItemWithMarkers) => {
      if (item.status === 'UPLOAD_FAILED' && item.file) {
        await uploadImage(item.file);
      } else if (item.status === 'COMPLETED') {
        setSortedImageList(
          sortedItems
            .filter((x) => isDefined(x.imageId))
            .map((x) => {
              return {
                imageId: x.imageId!,
              };
            }),
        );
        selectImage(item);
        setShowPlaceMarkersDialog(true);
      }
    },
    [uploadImage, sortedItems, selectImage, setShowPlaceMarkersDialog, setSortedImageList],
  );

  return (
    <OverlayLoader visible={isPendingImages || isGeodataProcessing}>
      <Stack spacing={1}>
        <Stack alignItems="center" direction="row" spacing={0.5}>
          <TextBox
            placeholder={t('search', { ns: 'common' })}
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
          <FileSelectionIcon
            accept=".jpg,.jpeg"
            color={theme.color.gray.dark}
            fixedWidth={true}
            icon={['fad', 'images']}
            multiple={true}
            title={'Lägg till bilder.'}
            onFilesSelected={async (files) => {
              for (const file of files) {
                await uploadImage(file);
              }
            }}
            onHoverStyle={{ icon: ['fas', 'images'] }}
          />
          <FileSelectionIcon
            accept=".jpg,.jpeg"
            color={theme.color.gray.dark}
            fixedWidth={true}
            icon={['fad', 'link']}
            multiple={true}
            title={'Länka lokala bilder.'}
            onFilesSelected={(files) => linkLocalImages(files)}
            onHoverStyle={{ icon: ['fas', 'link'] }}
          />
          <Icon
            color={theme.color.gray.dark}
            fixedWidth={true}
            icon={['fad', 'refresh']}
            title={t('refetch', { ns: 'common' })}
            onClick={() => refetchImages()}
            onHoverStyle={{ icon: ['fas', 'refresh'] }}
          />
        </Stack>

        {renderNoImagesPanel ? (
          <NoImagesPanel />
        ) : (
          <DataTable
            columns={columns}
            fixedLayout={true}
            highlightRow={highlightRow}
            items={filteredItems}
            pageSize={pageSize}
            tableStyle={tableStyle}
            onRowClicked={onRowClicked}
            onSortingChanged={setSortedItems}
          />
        )}
      </Stack>
    </OverlayLoader>
  );
}
