import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useDialog } from '../../../hooks/use-dialog';
import { Button } from '../../button/button';
import { CollapsableSection } from '../../skyview/right-menu/components/collapsable-section';
import { RightPanelFooter } from '../../skyview/right-menu/components/styles';
import { Stack } from '../../stack/stack';
import { GeodataImageList } from '../geodata-image-list/geodata-image-list';
import { GeodataContext } from '../geodata-state';
import { GcpList } from './gcp-list';
import { MarkerList } from './markers-list';
import { StartProcessDialog } from './start-process-dialog';

export const SfmViewerRightMenu = () => {
  const startProcessDialog = useDialog();
  const [imageListExpanded, setImageListExpanded] = useState(true);
  const [gcpListExpanded, setGcpListExpanded] = useState(true);
  const [markerListExpanded, setMarkerListExpanded] = useState(true);
  const { t } = useTranslation();
  const { isGeodataProcessing } = useContext(GeodataContext);

  return (
    <Container>
      <SfmRightPanelContent>
        <CollapsableSection
          expanded={gcpListExpanded}
          title={t('gcpList.title', { ns: 'cloudProcessing' })}
          onSetExpanded={(state) => setGcpListExpanded(state)}
        >
          <GcpList />
        </CollapsableSection>

        <CollapsableSection
          expanded={markerListExpanded}
          title={t('markerList.title', { ns: 'cloudProcessing' })}
          onSetExpanded={(state) => setMarkerListExpanded(state)}
        >
          <MarkerList />
        </CollapsableSection>

        <CollapsableSection
          expanded={imageListExpanded}
          title={t('imageList.title', { ns: 'cloudProcessing' })}
          onSetExpanded={(state) => setImageListExpanded(state)}
        >
          <GeodataImageList />
        </CollapsableSection>
      </SfmRightPanelContent>

      <RightPanelFooter>
        <Button
          color="primary"
          loading={isGeodataProcessing}
          variant="contained"
          width="100%"
          onClick={() => startProcessDialog.show()}
        >
          {t('gcpPage.processDialog.title', { ns: 'cloudProcessing' })}
        </Button>
      </RightPanelFooter>

      {startProcessDialog.render(<StartProcessDialog dialog={startProcessDialog} />)}
    </Container>
  );
};

const SfmRightPanelContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  // Render below header on scroll.
  z-index: 0;
`;

const Container = styled(Stack)`
  width: 500px;
  height: 100%;
  background-color: white;
`;
