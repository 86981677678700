import React, { useState } from 'react';
import styled from 'styled-components';

import { Button } from '../button/button';

type JsonEntry = {
  title: string;
  currentValue: string | number | boolean | JsonTemplate;
  optional: boolean;
  values?: string[];
};

type JsonTemplate = Record<string, JsonEntry>;

type EntryProps = {
  key: string;
  entry: JsonEntry;
  parentDisabled?: boolean;
};

const Entry = ({ key, entry, parentDisabled = false }: EntryProps) => {
  const [isEnabled, setIsEnabled] = useState(true);
  const [currentValue, setCurrentValue] = useState(entry.currentValue);
  const isDisabled = parentDisabled || (entry.optional && !isEnabled);

  const handleCheckboxToggle = () => {
    setIsEnabled(!isEnabled);
  };

  const renderValue = () => {
    if (typeof entry.currentValue === 'boolean') {
      return (
        <input
          checked={currentValue as boolean}
          disabled={isDisabled}
          type="checkbox"
          onClick={() => {
            entry.currentValue = !entry.currentValue;
            setCurrentValue(entry.currentValue);
          }}
        />
      );
    } else if (typeof entry.currentValue === 'string' && entry.values) {
      return (
        <select
          disabled={isDisabled}
          value={currentValue as string}
          onChange={(e) => {
            entry.currentValue = e.target.value;
            setCurrentValue(e.target.value);
          }}
        >
          {entry.values.map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </select>
      );
    } else if (typeof entry.currentValue === 'string' || typeof entry.currentValue === 'number') {
      return (
        <input
          disabled={isDisabled}
          type={typeof entry.currentValue === 'string' ? 'text' : 'number'}
          value={currentValue as string}
          onChange={(e) => {
            entry.currentValue =
              typeof entry.currentValue === 'number' ? Number(e.target.value) : e.target.value;
            setCurrentValue(entry.currentValue);
          }}
        />
      );
    } else if (typeof entry.currentValue === 'object') {
      return (
        <NestedContainer>
          {Object.entries(entry.currentValue).map(([nestedKey, nestedEntry]) => (
            <div key={nestedKey}>
              <Entry entry={nestedEntry} key={nestedKey} parentDisabled={isDisabled} />
            </div>
          ))}
        </NestedContainer>
      );
    }
    return null;
  };

  return (
    <EntryContainer key={key}>
      {entry.optional && (
        <input checked={isEnabled} type="checkbox" onChange={handleCheckboxToggle} />
      )}
      <label>{entry.title}</label>
      {renderValue()}
    </EntryContainer>
  );
};

type Props = {
  template: JsonTemplate;
  onConfirm: (payload: any) => void;
};

export const JsonEditor = ({ template, onConfirm }: Props) => {
  const [jsonString, setJsonString] = useState<string>('');

  const extractJson = () => {
    const iterateTemplate = (template: JsonTemplate): Record<string, any> => {
      const result: Record<string, any> = {};
      Object.entries(template).forEach(([key, entry]) => {
        if (typeof entry.currentValue === 'object') {
          result[key] = iterateTemplate(entry.currentValue);
        } else {
          result[key] = entry.currentValue;
        }
      });
      return result;
    };

    const extractedJson = iterateTemplate(template);
    setJsonString(JSON.stringify(extractedJson, null, 2));
    onConfirm(extractedJson);
  };

  return (
    <Component>
      {Object.entries(template).map(([key, entry]) => (
        <Entry entry={entry} key={key} />
      ))}
      <Button color="primary" variant="contained" onClick={extractJson}>
        Run custom pipeline
      </Button>
      {jsonString && <JsonOutput>{jsonString}</JsonOutput>}
    </Component>
  );
};

const Component = styled.div`
  padding: 1em;
  background-color: ${({ theme }) => theme.color.gray.lightest};
  border: 1px solid ${({ theme }) => theme.color.gray.light};
`;

const EntryContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;

  label {
    margin-left: 0.5em;
    margin-right: 0.5em;
    font-weight: bold;
  }

  input[type='text'],
  select {
    margin-left: 0.5em;
  }
`;

const NestedContainer = styled.div`
  margin-left: 2em;
  padding-left: 1em;
  border-left: 2px dashed ${({ theme }) => theme.color.gray.light};
`;

const JsonOutput = styled.pre`
  margin-top: 1em;
  padding: 1em;
  background-color: ${({ theme }) => theme.color.gray.light};
  border: 1px solid ${({ theme }) => theme.color.gray.medium};
  font-family: monospace;
  white-space: pre-wrap;
`;
