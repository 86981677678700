import { flip, offset, shift, useFloating } from '@floating-ui/react';
import { lighten } from 'polished';
import React, { useCallback, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { iiafe } from '../../../../utilities/async';
import { Intercom } from '../../../js/core/intercom';
import { LocalStorageHandler } from '../../../js/core/local-storage-handler';
import { publish, SubscriptionTopic } from '../../../js/messaging/pubsub';
import { TokenService } from '../../../js/services/authentication/token.service';
import { NavigationItem } from '../../../js/stores/user-store';
import { t } from '../../../js/utils/i18-next';
import { isDefined } from '../../../js/utils/variables';
import { useOutsideClick } from '../../hooks/use-outside.clicked';
import { useAngularJsContext } from '../../state/angular-js-context';
import { Icon } from '../icon/icon';
import { LabelledContainer } from '../labelled-container/labelled-container';
import { NoTranslateSpan } from '../no-translate/no-translate';
import { Select } from '../select/select';
import { Stack } from '../stack/stack';

const UserMenuDropdown = () => {
  const { $rootScope, $state } = useAngularJsContext();

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState($rootScope?.userStore.getActiveRoleOrDefault());
  const navItems = $rootScope?.userStore.getMainNavItems() ?? [];

  const { refs, floatingStyles } = useFloating({
    middleware: [offset(10), flip(), shift()],
    open: isDropdownOpen,
    onOpenChange: (nextOpen) => {
      setDropdownOpen(nextOpen);
    },
  });

  const containerRef = useRef<HTMLDivElement | null>(null);

  useOutsideClick(containerRef, (clickedOutside: boolean) => {
    if (isDropdownOpen && clickedOutside) {
      setDropdownOpen(false);
    }
  });

  const setActiveRole = useCallback(
    (newItem: NavigationItem) => {
      setSelectedRole(newItem);
      $rootScope?.userStore.setActiveRole(newItem);

      const nav = $rootScope?.userStore.getActiveRoleNav();
      if (isDefined(nav)) {
        iiafe(async () => {
          await $state?.go(nav as string);
        });
      }
    },
    [setSelectedRole, $rootScope, $state],
  );

  const isTopBarLogotypeOnly = Boolean($state?.current?.params?.topbar?.logotypeOnly);

  const handleSignOut = useCallback(async () => {
    publish(SubscriptionTopic.SignOut);
    new TokenService().remove();
    Intercom.instance.shutdown();
    $rootScope?.userStore.clear();
    LocalStorageHandler.instance.clearAll();

    if (isDefined($state)) {
      await $state.go('signin');
    }
  }, [$rootScope, $state]);

  if (isTopBarLogotypeOnly) {
    return null;
  }

  return (
    <Component className={isDropdownOpen ? 'active' : ''} direction="column" ref={containerRef}>
      <Header
        alignItems="center"
        direction="row"
        ref={refs.setReference}
        role="menu-button"
        spacing="16px"
        onClick={() => setDropdownOpen((prev) => !prev)}
      >
        <Stack direction="column" spacing="3px">
          <NoTranslateSpan>{$rootScope?.userStore.getName()}</NoTranslateSpan>
          <NoTranslateSpan>{selectedRole?.title}</NoTranslateSpan>
        </Stack>
        <Icon icon={['fas', 'user-circle']} size="xl" />
      </Header>

      {isDropdownOpen && (
        <DropdownContainer ref={refs.setFloating} role="menu" style={floatingStyles}>
          <MenuContainer direction="column">
            <MenuItem clickable={false}>
              <LabelledContainer text={t('activeRole', { ns: 'common' })}>
                {(formElementId) => (
                  <Select
                    id={formElementId()}
                    options={navItems.map((item: any) => ({ id: item.title, name: item.title }))}
                    value={selectedRole?.title ?? 'No selection'}
                    onChange={(e) => {
                      const selected = navItems.find((item) => item.title === e.target.value);
                      if (isDefined(selected)) {
                        setActiveRole(selected);
                      }
                    }}
                  />
                )}
              </LabelledContainer>
            </MenuItem>
            <MenuItem clickable={true} onClick={handleSignOut}>
              <span>{t('signOut', { ns: 'common' })}</span>
            </MenuItem>
          </MenuContainer>
        </DropdownContainer>
      )}
    </Component>
  );
};

const Component = styled(Stack)`
  color: var(--top-menu-text-color);
  cursor: pointer;
  padding: 10px 15px;

  &.active,
  &:hover {
    background-color: ${(props) => lighten(0.08, props.theme.color.gray.darkest)};
  }
`;

const Header = styled(Stack)`
  > div:first-child {
    margin-right: 15px;

    span:nth-child(1) {
      font-size: 1.1em;
    }
    span:nth-child(2) {
      font-size: 0.9em;
      opacity: 0.5;
    }
  }
`;

const DropdownContainer = styled.div`
  position: relative;
`;

const MenuContainer = styled(Stack)`
  padding: 5px;
  background-color: #fff;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
  border: 1px solid #aaa;
`;

const MenuItem = styled.div<{ clickable: boolean }>`
  padding: 10px;
  color: ${(props) => props.theme.color.gray.dark};

  ${(props) =>
    props.clickable &&
    css`
      &:hover {
        background-color: ${props.theme.color.gray.lightest};
        border-left: 5px solid ${props.theme.color.brand.dark};
        padding-left: 5px;
      }
      cursor: pointer;
    `}
`;

export { UserMenuDropdown };
